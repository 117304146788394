import {ControllerFlowAPI} from '@wix/yoshi-flow-editor'
import {Reservee} from '@wix/ambassador-table-reservations-v1-reservation/types'
import {
  CreateCheckoutResponse,
  CreateCheckoutRequest,
  Checkout as EcomCheckoutService,
  ApiChannelType,
} from '@wix/ambassador-checkout/http'
import {EpOrdersProxy} from '@wix/ambassador-ep-orders-proxy/http'

import {APP_DEF_ID, ECOM_BASE_URL} from '../utils/constants'

const getAuthorization = (flowAPI: ControllerFlowAPI) => {
  const token = flowAPI.controllerConfig.wixCodeApi.site.getAppToken?.(APP_DEF_ID)
  const instance = flowAPI.controllerConfig.appParams.instance

  return token ? token : instance
}

export const createCheckout = async (
  flowAPI: ControllerFlowAPI,
  reservationId: string,
  reservee: Reservee,
): Promise<CreateCheckoutResponse | undefined> => {
  const authorization = getAuthorization(flowAPI)
  const checkoutService = EcomCheckoutService(ECOM_BASE_URL).CheckoutService()({authorization})
  const checkoutRequest = createCheckoutRequest(reservationId, reservee)

  return checkoutService.createCheckout(checkoutRequest)
}

export const createOrderFromCheckout = async (flowAPI: ControllerFlowAPI, checkoutId: string) => {
  const authorization = getAuthorization(flowAPI)
  const checkoutService = EcomCheckoutService(ECOM_BASE_URL).CheckoutService()({authorization})

  return checkoutService.createOrder({id: checkoutId})
}

export const getOrderDetails = async (flowAPI: ControllerFlowAPI, orderId: string) => {
  const authorization = getAuthorization(flowAPI)
  const orderService = EpOrdersProxy(ECOM_BASE_URL).Orders()({authorization})

  const {order} = await orderService.getOrder({id: orderId})

  return order
}

function createCheckoutRequest(reservationId: string, reservee: Reservee): CreateCheckoutRequest {
  return {
    channelType: ApiChannelType.WEB,
    lineItems: [
      {
        quantity: 1,
        catalogReference: {
          catalogItemId: reservationId,
          appId: APP_DEF_ID,
        },
      },
    ],
    checkoutInfo: {
      billingInfo: {
        contactDetails: {
          firstName: reservee.firstName || undefined,
          lastName: reservee?.lastName || undefined,
          phone: reservee?.phone || undefined,
        },
      },
      buyerInfo: {
        email: reservee?.email || undefined,
        contactId: reservee?.contactId || undefined,
      },
      customSettings: {
        disabledManualPayment: false,
      },
    },
  }
}

export const checkoutService = {
  createCheckout,
  createOrderFromCheckout,
  getOrderDetails,
}
